import { enableProdMode, inject, provideAppInitializer } from '@angular/core';
import { environment } from './environments/environment';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { MatDateFormats } from '@angular/material/core';
import { IconsService } from './app/services/icons.service';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient } from '@angular/common/http';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { provideMaterialTheme } from '../projects/theme/src/lib/providers/provide-material-theme';

export const DATE_FORMAT: MatDateFormats = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY-MM-DD',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY-MM-DD',
  },
};

function initializeApp(iconService: IconsService) {
  return (): Promise<unknown> => {
    iconService.registerIcons();
    return Promise.resolve();
  };
}

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideAnimations(),
    provideHttpClient(),
    provideMomentDateAdapter(DATE_FORMAT),
    provideAppInitializer(() => {
      const initializerFn = initializeApp(inject(IconsService));
      return initializerFn();
    }),
    provideMaterialTheme(),
  ],
}).catch((err) => console.error(err));
